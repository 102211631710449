// MobILe bRowSerS SuCk
// On mobile browsers overflow rules do not work on the body element...
.body-controller {
	overflow-x: hidden;
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}

html {
	--scroll-behavior: smooth;
	scroll-behavior: smooth;
}