h1, h2, h3, h4, h5 {
	font-weight: 600;
	font-style: italic;
	line-height: 1.2;
}

@mixin responsive-big-text {
	font-size: 1.6rem;
	line-height: 1.2;

	@include breakpoint(medium) {
		font-size: 1.7rem;
	}

	@include breakpoint(large) {
		font-size: 1.8rem;
	}

	@include breakpoint(xlarge) {
		font-size: 2rem;
	}
}

.big-text {
	@include responsive-big-text;

}