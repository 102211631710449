#story-single {
	.super-inner-wrap {
		padding-top: 0;
	}

	.go-back-anchor {
		align-self: flex-start;
		display: block;
		margin-bottom: 10px;
		margin-top: 10px;
		max-width: 200px;
		.fancy-arrow {
			color: $primary-color;
			display: inline-block;
			transform: rotate(-90deg);
			font-size: 1.1rem;
		}
		transiton: color .3s ease;
		font-weight: bold;
		color: $gray;

		&:hover {
			color: $primary-color;
		}

		&:active, &:focus {
			color: darken($primary-color, 10);
		}

	}

	.constrain-wrap {
		max-width: 850px;
		width: 100%;
		margin-left: auto;
	}


	.embed-container {
		position: relative;
		padding-bottom: 56.25%;
		width: 100%;
		overflow: hidden;
		box-shadow: 2px 2px 8px 0 rgba(0,0,0, .25);

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.stories-author {
		font-size: 1.2rem;
		margin-top: .5rem;
	}

	.header-wrap {
		display: flex;
		align-content: flex-start;
		justify-content: space-between;

		padding: .5rem 0;

		.stories-author {
			color: $gray;
			font-style: italic;
		}

		.fancy-btn {
			align-self: flex-start;
		}
	}

	.discussion-points {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 1rem;

		@include breakpoint(large) {
			flex-wrap: nowrap;
		}

		.discussion-single {
			margin-top: 1rem;
			padding: 1rem;
			.discussion-title, .discussion-desc {
				padding-left: .5rem;
				padding-right: .5rem;
			}

			.discussion-title {
				display: flex;
				align-items: center;
				align-content: center;
				font-style: normal;

				h3 {
					margin-bottom: 0;
				}

				img {
					height: 55px;
					margin-right: 10px;
				}
			}

			.discussion-desc {
				margin-top: .5rem;
				color: $gray;
				width: 100%;
				max-width: 450px;
			}

			&:first-child {
				.discussion-title {
					color: $primary-color;
				}
			}

			&:last-child {
				.discussion-title {
					color: $tertiary-color;
				}
			}
		}
	}
}

#comments {
	padding: 1rem !important;
}



// Comment thread edits
.wpd-comment-text {
	p {
		font-size: 1rem;
		color: $black;
	}
}


.wpd-bottom-custom-fields {
	display: flex;

	.wpd-custom-field {
		display: flex;

		.wpd-cf-label {
			display: flex;
			font-size: .8rem;
			border-radius: 5px;
			background-color: $secondary-color;
			color: white;
			font-weight: bold;
			width: auto;
			z-index: 1;
			line-height: 1;
			display: none;
		}

		.wpd-cf-value {
			display: flex;
			font-weight: bold;
			width: auto;
			border-radius: 5px;
			font-size: 1.1rem;
			border-bottom: unset;
			color: white;
			line-height: 1;
			opacity: 1;
			z-index: 0;
			background-color: $quinary-color;
		}
	}
}

.wpd-field-group-title {
	display: flex;
	font-size: .8rem;
	border-radius: 5px;
	padding-left: 10px !important;
	background-color: $secondary-color;
	color: white;
	font-weight: bold;
	width: auto;
	z-index: 1;
	line-height: 1;
}

.wpd-field-label {
	font-weight: bold;
}
