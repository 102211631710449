#project-hero {
	.hero-col-grid {
		display: flex;
		flex-flow: column nowrap;

		@include breakpoint(xlarge) {
			flex-flow: row nowrap
		}

		> div {
			width: 100%;

			@include breakpoint(xlarge) {
				width: 50%;
			}
		}
	}

	.hero-content-wrap {
		position: relative;
		overflow: visible;
		padding-top: 5rem;
		padding-left: 0;
		padding-right: 2rem;
		padding-bottom: 4rem;
		align-self: flex-start;
		max-width: 30rem;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		@include breakpoint(xlarge) {
			padding-top: 3rem;
		}

		&::after {
			content: '';
			background-color: $quaternary-color;
			position: absolute;

			height: 100%;
			width: 110%;
			left: -14%;
			bottom: 0;
			z-index: -1;

			transform: skew(-5deg);


			// Huh this actually worked really well. Way better then the nonsense going on the front page
			@include breakpoint(medium) {
				width: calc(110% + #{$global-gutter-medium*2});
				left: -$global-gutter-medium*2;
			}
		}

		a {
			align-self: flex-start;
			margin-top: 2rem;
		}

		.hero-desc {
			padding-top: 1.5rem;
		}
	}

	.hero-projects-container {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		.project-icon {
			margin-top: -1rem;
			margin-bottom: 2rem;
			max-width: 450px;
			width: 100%;
			margin-right: auto;
		}

		.hero-excerpt {
			margin-left: 0;
			line-height: 1.6;
			margin-top: 1rem;
			max-width: 450px;
			margin-right: auto;

			@include mq(l) {
				margin-top: 2rem;
				margin-left: 4rem;
			}
		}

		.project-hero-single {
			display: flex;
			flex-flow: column nowrap;
			align-items: center;
			justify-content: space-between;
			padding: 5px;
			transform: scale(1);
			transition: all .5s ease;

			&:hover {
				transform: scale(1.05);
			}

			&:focus, &:active {
				transform: scale(1.08);
			}

			.project-circle {
				position: relative;
				width: 100%;
				padding-bottom: 100%;
				border-radius: 50%;
				background-color: currentColor;

				.project-title {
					position: absolute;
					color: white;
					z-index: 1;
					height: 100%;
					width: 100%;
					padding: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: 500;
					text-align: center;

					font-size: 1.2rem;
					line-height: 1.2;

					@include breakpoint(medium) {
						font-size: 1.4rem;
					}

					@include breakpoint(large) {
						font-size: 1.6rem;
					}

					@include breakpoint(xlarge) {
						font-size: 1.8rem;
					}
				}
			}

			.project-line {
				margin-top: -10px;
				margin-bottom: -10px;
				height:95px;
				border-left: 3px dashed currentColor;
			}


			&:nth-child(even) {
				flex-flow: column-reverse nowrap;
			}


			&.blue {
				color: $primary-color;
			}

			&.orange {
				color: $secondary-color;
			}

			&.green {
				color: $tertiary-color;
			}

			&.light-blue {
				color: $quaternary-color;
			}

			&.light-orange {
				color: $quinary-color;
			}

			&.light-green {
				color: $senary-color;
			}
		}
	}
}


.project-section {
	.project-col-grid {
		display: flex;
		flex-flow: column nowrap;

		@include breakpoint(xlarge) {
			flex-flow: row nowrap
		}

		> div {
			width: 100%;

			@include breakpoint(xlarge) {
				width: 50%;
			}
		}
	}

	/** Project circle and image schmutz */
	.project-icon-image {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-bottom: -5rem;

		@include breakpoint(xlarge) {
			margin-bottom: 0;
		}
	}

	.project-circle {
		position: relative;
		border-radius: 50%;
		display: flex;
		flex-flow: column nowrap;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			z-index: -1;
			padding-bottom: 100%;
			border-radius: 50%;
			background: currentColor;
		}

		&::before {
			content: '';
			position: absolute;
			top: 30%;
			left: 95%;
			width: 70px;
			border-top: 3px dashed currentColor
		}


		.project-title {
			color: white;
			z-index: 1;
			padding: 20px;
			min-width: 130px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 500;
			text-align: center;

			font-size: 1.2rem;
			line-height: 1.2;

			@include breakpoint(medium) {
				margin-top: 10%;
				font-size: 1.4rem;
			}

			@include breakpoint(large) {
				font-size: 1.6rem;
			}

			@include breakpoint(xlarge) {
				font-size: 1.8rem;
			}
		}
	}

	.project-icon {
		@include breakpoint(medium) {
			padding: 0 1rem 1rem;
		}
	}

	.project-line {
		margin-left: -10px;
		margin-right: -10px;
		width: 60px;
		border-top: 3px dashed currentColor;
	}

	.project-image-container {
		padding-left: 40px;
	}

	.project-content-wrap {
		position: relative;
		overflow: visible;
		padding-top: 8rem;
		padding-left: 0;
		padding-right: 2rem;
		padding-bottom: 6rem;
		align-self: flex-start;
		max-width: 30rem;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		@include breakpoint(xlarge) {
			padding-top: 6rem;
		}

		&::after {
			content: '';
			background-color: currentColor;
			position: absolute;

			height: 100%;
			width: 110%;
			left: -14%;
			bottom: 0;
			z-index: -1;

			transform: skew(-5deg);


			// Huh this actually worked really well. Way better then the nonsense going on the front page
			@include breakpoint(medium) {
				width: calc(110% + #{$global-gutter-medium*2});
				left: -$global-gutter-medium*2;
			}
		}

		a {
			align-self: flex-start;
			margin-top: 2rem;
		}

		.project-desc {
			color: white;

			p {
				margin-bottom: 0;
			}

			@include breakpoint(xlarge) {
				padding: 2rem;
			}
		}
	}



	&.blue {
		color: $primary-color;
	}

	&.orange {
		color: $secondary-color;
	}

	&.green {
		color: $tertiary-color;
	}

	&.light-blue {
		color: $quaternary-color;
	}

	&.light-orange {
		color: $quinary-color;
	}

	&.light-green {
		color: $senary-color;
	}
}