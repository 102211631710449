.fancy-btn {
	position: relative;
	padding: .5rem .5rem;

	color: $gray;
	font-weight: bold;
	font-size: 1.5rem;
	z-index: 1;
	backface-visibility: hidden;

	@include breakpoint(medium) {
		font-size: 1.6rem;
	}

	// Important to skew on the after element also more animation control :)
	&::before {
		content: '';
		position: absolute;
		top: 85%;
		bottom: 0;
		left: 0;
		height: 15%;
		width: 100%;
		background-color: $primary-color;
		z-index: -1;
		transition: all .4s ease;
		transform: skew(-20deg);
		transform-origin: left;
		backface-visibility: hidden;
	}

	&:hover {
		color: $gray;
	}

	&:hover, &:active, &:focus {
		&::before {
			transform: skew(-25deg);
			width: 112%;
		}
	}

	&:active {
		&::before {
			// Fancy way to give an active state without having to change the color in the modifiers :D
			filter: brightness(.85);
		}
	}
}

.btn-text-white {
	color: white;

	&:hover {
		color: white;
	}
}

.btn-green {
	&::before {
		background-color: $tertiary-color;
	}
}

.btn-orange {
	&::before {
		background-color: $secondary-color;
	}
}