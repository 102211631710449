.form-wrap {
	width: 100%;
}


.event-card {
	position: relative;
	padding-bottom: 85%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	.card-wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 1rem;
		display: flex;
		flex-flow: column nowrap;
	}

	.title {
		margin-top: auto;
		font-size: 1.2em;
		color: white;
		font-weight: 500;
	}

	.date {
		font-size: 1.5em;
		font-weight: 600;
		color: white;
		line-height: 1;
	}

	.venue {
		font-size: 1em;
		font-weight: 400;
		color: white;
		margin-top: .6rem;
	}

	span {
		line-height: 1.3;
	}
	transition: all .3s ease;
	box-shadow: 0 0 0 2000px rgba(0,0,0, .3) inset;
	&:hover, &:focus, &:active {
		box-shadow: 0 0 0 2000px rgba(0,0,0, .2) inset;
	}

	&:active {
		box-shadow: 0 0 0 2000px rgba(0,0,0, .15) inset;

	}
}

.event-card-grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px;
	max-width: 400px;
	width: 100%;
	margin: 20px auto;


	@include breakpoint(medium) {
		grid-template-columns: 1fr 1fr;
		max-width: 600px;
		margin-left: unset;
		margin-right: auto;
	}
}

#contact {
	margin-bottom: 50px;
	.super-inner-wrap {
		position: relative;
		width: 1000px;
	}

	color: white;
	.fancy-angle-wrap {
		@include breakpoint(medium) {
			padding-right: 60px;
		}

		@include breakpoint(large) {
			padding-right: 120px;
		}

		&::after {
			content: '';
			background-color: $tertiary-color;
			position: absolute;
			display: block;
			bottom: 0;
			left: -30%;
			right: 0;
			width: 130%;
			z-index: -1;
			height: 100%;


			@include breakpoint(medium) {
				transform: skew(-5deg)
			}
		}

	}
	.form-wrap {
		width: 100%;
		max-width: 30rem;

	}

	.gfield_required {
		color: white;
	}

	.gfield_label {
		font-size: 1.3em;
	}

	.ginput_container {
		margin-top: 0;
	}

	input[type="submit"] {
		padding: 1rem 2.5rem;
		font-weight: 500;
	}

	input, textarea, select {
		border: unset !important;
		padding: 10px;
	}
}