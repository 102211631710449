$primary-color: #27AAE1;
$secondary-color: #F47929;
$tertiary-color: #8DC63F;

// Light primaries
$quaternary-color: #C7EEFF;
$quinary-color: #E6A647;
$senary-color: #CEEAA8;

$gray: #4A4A4A;
$white: #fff;
$black: #4A4A4A;