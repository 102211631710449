#mission-hero {

	.super-inner-wrap {
		padding-bottom: 0;
	}
	.hero-col-grid {
		display: flex;
		flex-flow: column-reverse nowrap;

		@include breakpoint(xlarge) {
			flex-flow: row nowrap
		}

		> div {
			width: 100%;

			@include breakpoint(xlarge) {
				width: 50%;
			}
		}
	}

	.hero-content-wrap {
		position: relative;
		overflow: visible;
		padding-top: 2rem;
		padding-left: 0;
		padding-right: 2rem;
		padding-bottom: 4rem;
		color: white;
		align-self: flex-start;
		max-width: 30rem;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		&::after {
			content: '';
			background-color: $secondary-color;
			position: absolute;

			height: 100%;
			width: 110%;
			left: -14%;
			bottom: 0;
			z-index: -1;

			transform: skew(-5deg);


			// Huh this actually worked really well. Way better then the nonsense going on the front page
			@include breakpoint(medium) {
				width: calc(110% + #{$global-gutter-medium*2});
				left: -$global-gutter-medium*2;
			}
		}

		.hero-desc {
			padding-top: 1.5rem;
		}
	}

	.hero-image-container {
		align-self: center;
		margin-right: auto;
		position: relative;
		width: auto !important;
		margin-bottom: -1rem;
		z-index: 3;

		@include breakpoint(xlarge) {
			z-index: -2;
		}

		&::after {
			content: '';
			position: absolute;
			top: -50px;
			right: -50px;
			width: 200px;
			height: 200px;
			border-radius: 50%;
			background-color: $quinary-color;
			z-index: -3;
		}

		.overflow-svg {
			position: absolute;
			top: -60px;
			left: -15px;
			width: 215px;
			z-index: 2;
			@include breakpoint(medium) {
				left: 30px;
			}
		}

		.hero-image {
			width: 300px;
			max-width: 100%;
			margin-right: auto;
			position: relative;
			z-index: -2;

			@include breakpoint(medium) {
				width: 400px;
			}

			@include breakpoint(large) {
				width: 450px;
			}

			@include breakpoint(xlarge) {
				width: 525px;
			}
		}
	}
}


#mission-map {
	.super-inner-wrap {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 0;
		padding-bottom: 0;
		align-items: center;
		@include breakpoint(xlarge) {
			flex-flow: row nowrap
		}

		> div {
			width: 100%;

			@include breakpoint(xlarge) {
				width: 50%;
			}
		}
	}

	.map-image-container {
		max-width: 450px;
		margin-top: -20px;
	}

	.map-content-wrap {
		align-self: center;
		margin-top: auto;
		margin-bottom: auto;
		padding: 1rem;
		position: relative;
		overflow: visible;
		max-width: 30rem;

		&::after {
			content: '';
			position: absolute;
			top: -10%;
			left: -5%;
			right: -5%;
			bottom: -10%;
			z-index: -1;
			background: $quaternary-color;
			transform: skew(-5deg);
		}

		@include breakpoint(medium) {
			padding: 2rem;

			&::after {

				top: -30%;
				left: -15%;
				right: -15%;
				bottom: -30%;
			}
		}
	}
}

#mission-marketing {
	margin-top: 60px;
	.super-inner-wrap {
		display: flex;
		flex-flow: column-reverse nowrap;
		padding-top: 0;
		align-items: center;
		@include breakpoint(xlarge) {
			flex-flow: row nowrap
		}


	}

	.marketing-image-container {
		max-width: 500px;
		padding: 1rem;

		@include breakpoint(xlarge) {
			max-width: 100%;
		}
	}

	.marketing-content-wrap {
		max-width: 100%;
	}

	.marketing-desc {
		width: 25rem;
		max-width: 100%;
		margin: 1rem;

		p {
			margin-bottom: 0;
		}
	}
}

#mission-vision {
	background-image: url('../images/scrape.svg');
	background-position: top center;
	background-size: auto 300px;
	background-repeat: no-repeat;

	@include breakpoint(large) {
		background-position: top right;
	}

	.super-inner-wrap {
		padding-bottom: 100px;
	}
	h3 {
		text-align: center;
		font-weight: 300;
		max-width: 680px;
		margin: 0 auto;
	}

	a {
		margin-top: 4rem;
		text-align: center;
		align-self: center;
	}
}