/************************
  HERO
*************************/
#home-hero {
	color: white;
	position: relative;
	z-index: 0;
	background-size: cover;
	background-position: center center;

	.super-inner-wrap {
		margin-top: 150px;
		z-index: 1;
	}

	.background-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: rgba(0, 0, 0, 0.55);
		z-index: -1;
	}

	.hero-description {
		font-size: 2rem;
		margin-top: 2rem;
		margin-bottom: 3rem;
		font-weight: 300;

		@include responsive-big-text;
	}

	.hero-content {
		max-width: 25rem;
		width: 100%;
	}


	.hero-scroll-container {
		position: absolute;
		bottom: 3rem;

		a {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			transition: all .3s ease;
			color: white;

			&:hover, &:active, &:focus {
				color: $secondary-color;
			}

			svg {
				margin-left: .5rem;
				height: 1.5rem;
				animation-name: arrow-bounce;
				animation-duration: 1.5s;
				animation-iteration-count: infinite;
				transition: transform 1s ease;
			}

			@keyframes arrow-bounce {
				0% {transform: translateY(0);}
				25% {transform: translateY(.3rem);}
				100% {transform: translateY(-.3rem);}
			}
		}
	}
}
/************************
  MAIN
*************************/
.home-section {
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	max-width: $global-width;

	@include breakpoint(medium) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	@include breakpoint(large) {
		padding-top: 5rem;
		padding-bottom: 5rem;
		margin-right: 250px;
		min-height: 100vh;
	}

	.home-inner-wrap {
		position: relative;
		display: flex;
		flex-flow: column-reverse nowrap;
		flex-grow: 1;
		z-index: 1;
		padding-left: $global-gutter-small;
		padding-right: $global-gutter-small;



		@include breakpoint(medium) {
			padding-left: $global-gutter-medium;

			&::after {
				content: '';
			}

		}
		@include breakpoint(large) {
			padding-left: $global-gutter-large;

			&::after {
				content: none;
			}
		}
		@include breakpoint(xlarge) {
			flex-flow: row nowrap;
		}
	}

	.home-content-wrap {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		color: white;
		position: relative;
		padding: 1.5rem 1.5rem 1.5rem 0;


		&::after {
			content: '';
			background-color: $quinary-color;
			position: absolute;
			display: block;
			bottom: 0;
			left: -14%;
			right: 0;
			width: 110%;
			z-index: -1;
			height: 125%;

			transform: skew(-5deg)
		}


		@include breakpoint(medium) {

			&::after {
				left: -50%;
				width: 175%;

			}
		}

		@include breakpoint(large) {

			&::after {
				left: -50%;
				width: 215%;

			}
		}

		@include breakpoint(xlarge) {
			position: static;

			&::after {
				left: -50%;
				width: 125%;
				height: 100%;
			}
		}

		.home-description {
			@include responsive-big-text;
		}

		.home-title {
		}

		@include breakpoint(medium) {
			max-width: 25rem;
		}


		@include breakpoint(xlarge) {
			max-width: 28rem;
			padding-right: 3rem;
		}
	}

	.home-image-container {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1rem;
		margin-top: 1rem;
		width: 60vw;
		height: 60vw;
		min-height: 300px;
		min-width: 300px;
		max-width: 400px;
		max-height: 400px;
		overflow: hidden;


		.home-image {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			max-width: unset;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}


		@include breakpoint(medium) {
			margin-left: unset;
			margin-right: auto;
		}

		@include breakpoint(xlarge) {
			margin-top: auto;
			margin-bottom: auto;
			margin-left: auto;
			margin-right: unset;
			max-width: 60vh;
			max-height: 60vh;
		}

	}

	.home-link {
		margin-top: 1rem;
		align-self: flex-start;
	}


	&:nth-child(3n+1) {
		.home-content-wrap::after {
			background-color: $secondary-color;
		}
	}


	&:nth-child(3n+0) {
		.home-content-wrap::after {
			background-color: $tertiary-color;
		}
	}
}

.home-social-feed {
	h2 {
		color: $primary-color;
		margin-bottom: 1rem;
	}

	.crt-logo {
		display: none !important;
	}
}


#projects {
	min-height: auto;

	.home-inner-wrap {
		flex-flow: column nowrap;
	}

	.title-wrap {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin-bottom: 1rem;

		.fancy-btn {
			display: none;
			align-self: center;

			@include breakpoint(medium) {
				display: block;
			}
		}
	}

	.hide-above-sm {
		font-size: 1.6em;
		display: block;
		margin: 0 auto;

		@include breakpoint(medium) {
			display: none;
		}
	}
}


.sm-section {
	min-height: 100% !important;
	padding-top: 2rem;
	padding-bottom: 2rem;
	@include breakpoint(medium) {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	@include breakpoint(large) {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	@include breakpoint(large) {
		margin-right: 260px;
	}

	.home-image-container {
		@include breakpoint(large) {

			margin-top: 40px !important;
			margin-bottom: 40px !important;
		}
	}

	.fancy-btn {
		font-size: 1.6em;
	}

}