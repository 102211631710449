/*
 * Generic footer layout engine 📏
 * A big note custom styling should be avoided here!
 */
$footer-width: $global-width;
$footer-bg-color: $gray;
$footer-padding-t: 2rem;
$footer-padding-b: 4rem;
$footer-padding-lr: 1rem;

$footer-cell-padding-lr: 1rem;
$footer-cell-padding-tb: 1rem;
// Important setting here aligns the cells on small!
$footer-cell-width-sm: 275px;

$footer-menu-width: 24rem;
$footer-menu-item-padding-t: 0.6rem; // menu item padding top
$footer-menu-item-padding-b: 0.6rem; // menu item padding bottom
$footer-menu-item-padding-lr: 1rem;
$footer-menu-item-font-weight: bold;

.super-footer {
	display: flex;
	flex-flow: column nowrap;
	padding-top: $footer-padding-t;
	padding-left: $footer-padding-lr;
	padding-right: $footer-padding-lr;
	padding-bottom: $footer-padding-b;
	background-color: $footer-bg-color;
	@at-root {
		.super-footer-inner-wrap {
			display: flex;
			flex-flow: column nowrap;
			max-width: $footer-width;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
		}

		.super-footer-grid {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-start;
			justify-content: center;
			width: $footer-cell-width-sm;
			margin-left: auto;
			margin-right: auto;

			@include breakpoint(medium) {
				width: 100%;
				justify-content: space-between;
			}
		}

		.super-footer-cell {
			padding: $footer-cell-padding-tb $footer-cell-padding-lr;
			display: block;
			max-width: 100%;
			width: $footer-cell-width-sm;

			@include breakpoint(medium) {
				width: auto;
			}
		}
		.super-footer-logo-container {
			width: 100%;

			.super-footer-logo {
				max-width: 100%;
				height: 45px;
				margin-bottom: 1rem;
			}

			@include breakpoint(medium) {
				width: auto;
			}
		}

		// Ambigious selector to help make this work anywhere!
		.super-footer-menu {
			width: $footer-cell-width-sm;

			@include breakpoint(medium) {
				width: $footer-menu-width;
			}

			ul {
				display: flex;
				flex-flow: column nowrap;

				list-style: none;
				width: 100%;

				@include breakpoint(medium) {
					margin-left: -$footer-menu-item-padding-lr;
				}

				li {
					width: auto;
					font-weight: $footer-menu-item-font-weight;
					text-align: center;

					a {
						padding-top: $footer-menu-item-padding-t;
						padding-bottom: $footer-menu-item-padding-b;
					}

					@include breakpoint(medium) {
						width: 50%;
						text-align: left;

					}
				}

				@include breakpoint(medium) {
					flex-flow: row wrap;
				}
			}
		}

		.super-footer-copyright {
			text-align: center;
		}
	}
}

// extra custom styles
.super-footer {
	color: white;

	.super-footer-menu {
		a {
			color: white;
			transition: color 0.3s ease;
			text-transform: uppercase;

			&:hover {
				color: $secondary-color;
			}
		}
	}

	a {
		color: $white;
		display: block;
	}

	.footer-info {
		margin: 0 auto;
		@include breakpoint(855px) {
			margin: unset;
		}
	}

	.footer-socials {
		display: flex;
		flex-flow: row nowrap;
		align-content: center;
		justify-content: center;

		i {
			font-size: 2rem;
			padding: .5rem;

			color: $primary-color;
			transition: all .3s ease;

			&:hover, &:focus {
				filter: brightness(1.2);
			}

			&:active {
				color: white;
			}
		}
	}
}


.footer-form {

	#mc_embed_signup_scroll {
		display: flex;
		flex-flow: column nowrap;
	}

	.email {
		padding: 10px;
		border-radius: 100px;
		border: none;
		width: 100%;
	}

	input[type="submit"] {
		background: none;
		margin-bottom: 0;
		padding: .5rem;
		padding-left: 2rem;
		font-weight: bold;
		font-size: 1.2rem;
	}

	.fancy-btn {
		align-self: flex-end;
		margin-left: auto;
		padding: 0;
		margin-top: .5rem;
	}
}