.overflow-header {
	position: fixed;
	display: flex;
	flex-flow: row nowrap;
	padding: 1rem;
	width: auto;
	max-width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	color: white;

	@include breakpoint(large) {
		padding-left: 0;
		padding-right: 0;
	}

	.header-logo-container {
		width: 130px;
		transition: width 0.3s ease;

		@include breakpoint(medium) {
			width: 140px;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba($gray, 0.95);
		transition: all 0.5s ease;
		z-index: 0;
		opacity: 0;
		transform: translateY(-100%);
	}

	// Fancy overlay js watcher
	&[data-element-watcher-active] {
		.header-logo-container {
			width: 120px;

			@include breakpoint(medium) {
				width: 125px;
			}
		}
		&::before {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&[data-element-watcher-name="hero"] {
		#desktop-menu {
			a {
				color: white;

				&:hover {
					color: $secondary-color;
				}

				&:active {
					color: darken($secondary-color, 10);
				}
			}

			// The js initializes on the A element itself
			[data-element-watcher-active] {
				color: $gray;
				&:hover {
					color: $secondary-color;
				}

				&:active {
					color: darken($secondary-color, 10);
				}
			}
		}


	}

	.header-topbar {
		position: relative;
		display: flex;
		width: 100%;
		margin-left: $global-gutter-small;
		margin-right: $global-gutter-small;

		@include breakpoint(medium) {
			margin-left: $global-gutter-medium;
			margin-right: $global-gutter-medium;
		}

		@include breakpoint(xlarge) {
			margin-left: $global-gutter-large;
			margin-right: $global-gutter-large;
		}
		.header-inner-wrap {
			display: flex;
			flex-flow: row nowrap;
			align-self: flex-start;
			justify-self: flex-start;
			width: 100%;
			max-width: $global-width;
		}

		.header-sidebar {
			position: absolute;
			top: 15px;
			right: 0;
			width: $sidebar-width;
			display: flex;
			flex-flow: row nowrap;
			justify-content: flex-end;

			@include breakpoint(large) {
				flex-flow: column nowrap;
			}

			a {
				color: $gray;
				font-weight: bold;
				font-size: 1.2rem;
				transition: all 0.3s ease;

				&:hover {
					color: $secondary-color;
				}

				&:active {
					color: darken($secondary-color, 10);
				}
			}

			.sub-menu {
				a {
					font-weight: normal;
				}
			}

			.donate-btn {
				margin-left: auto;
				color: white;

				&:hover,
				&:active,
				&:focus {
					color: white;
				}
			}

			#desktop-menu {
				margin-left: auto;
				margin-top: 6rem;
				display: none;
				@include breakpoint(large) {
					display: flex;
				}
			}
		}
	}
}

// mobile time!
[data-bm-toggle] {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-self: flex-end;
	width: fit-content;
	margin-left: 1.5rem;
	transform: rotate(0);
	transition: all 0.5s ease;

	.toggle-line {
		color: white;
		height: 45px;
		margin-left: -8px;
		margin-right: -8px;

		svg {
			height: 100%;
			width: auto;
		}
	}

	@include breakpoint(large) {
		display: none;
	}

	&[data-bm-popup-is-open="true"] {
		transform: rotate(25deg);
	}
}

// Importants for overwriting the fancy-btn rules~
.header-contact {
	padding-left: 0.8rem !important;
	padding-right: 0.8rem !important;
	position: fixed !important;
	bottom: 0;
	right: 20px;

	@include breakpoint(medium) {
		right: 40px;
	}

	&::before {
		height: 100% !important;
		top: 0 !important;
	}
}

#mobile-nav-popup {
	z-index: 10000;
	color: white;
	overflow-y: auto;

	[data-bm-popup-close] {
		position: absolute;
		top: 2rem;
		right: 2rem;

		display: flex;
		flex-flow: row nowrap;
		align-self: flex-end;
		width: fit-content;
		transform: rotate(25deg);

		@include breakpoint(medium) {
			right: $global-gutter-medium;
		}

		.toggle-line {
			color: white;
			height: 45px;
			margin-left: -8px;
			margin-right: -8px;

			svg {
				height: 100%;
				width: auto;
			}
		}
	}

	nav {
		margin-top: 2rem;
		position: relative;

		&::after {
			content: "";
			background-color: white;
			position: absolute;
			bottom: -10px;
			right: 14px;
			width: 40%;
			height: 3px;
		}
	}

	li {
		a {
			color: white;
			&:hover {
				color: $secondary-color;
			}

			&:active {
				color: darken($secondary-color, 10);
			}
		}

		&.is-active {
			a {
				color: $secondary-color;
			}
		}

		ul {
			a {
				font-weight: 400;
			}
		}
	}
}
