/**
 * Super section :D
 * A generic section that contains content
 * The section is set to flex column for easy placement of children elements 🙌
 **/

$super-padding-tb: 75px;
$super-padding-lr: 20px;

// Width for sections that should have inner-wrap constrained
$constrain-width: 1000px;

.super-section {
	display: flex;
	flex-flow: row wrap;
	background-position: center center;
	background-size: cover;
	width: 100%;
	max-width: 100%;

	.super-inner-wrap {
		display: flex;
		flex-flow: column nowrap;
		padding: $super-padding-tb $super-padding-lr;
		width: $global-width;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		justify-self: stretch;
		align-self: stretch;
		overflow: visible;

		@include breakpoint(medium) {
			margin-left: $global-gutter-medium;
			margin-right: $global-gutter-medium
		}

		@include breakpoint(large) {
			margin-right: $sidebar-width + $global-gutter-large;

		}

		@include breakpoint(xlarge) {
			margin-left: $global-gutter-large;

		}
	}


	&.super-center {
		>.super-inner-wrap {
			align-items: center;
			justify-content: center;
		}

		/*h1, h2, h3 {
			&:not()
			text-align: center;
		}*/
	}

	&.super-flex-end {
		>.super-inner-wrap {
			justify-content: flex-end;
		}
	}

	&.super-constrain {
		.super-inner-wrap {
			width: $constrain-width;
		}
	}
}


.super-inner-content {
	width: 100%;
	text-align: left;
}
