
.f-grid {
	display: flex;
	flex-flow: row wrap;
	--cell-size-sm: 100%;
	--cell-size-md: 50%;
	--cell-size-lg: 33.33%;
	--grid-gap: 15px;
	max-width: calc(100% + var(--grid-gap) * 2);

	margin: calc((var(--grid-gap) * .5) * -1);

	.f-cell {
		display: flex;
		flex-flow: column nowrap;
		max-width: calc(100% - var(--grid-gap));
		flex-basis: calc(var(--cell-size-sm) - var(--grid-gap));
		margin: calc(var(--grid-gap) * .5);

		@include mq(m) {
			flex-basis: calc(var(--cell-size-md) - var(--grid-gap));
		}

		@include mq(l) {
			flex-basis: calc(var(--cell-size-lg) - var(--grid-gap));
		}
	}
}

.project-grid {
	max-width: 400px;
	width: 100%;
	margin: 0 auto;
	align-self: stretch;

	@include breakpoint(medium) {
		max-width: 100%;
	}
}


.project-card {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	//height: auto;
	overflow: hidden;
	color: white;
	.card-image-wrap {
		position: relative;
		overflow: hidden;
		padding-bottom: 90%;
		transition: all .3s ease;


		.info-wrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			overflow: hidden;
			margin: 1.5rem;
			transition: all .3s ease;
			font-size: 1.2em;
			color: white;
			line-height: 1.1;
			font-weight: 400;
			opacity: 0;
			visibility: hidden;

			mask-image: linear-gradient(to top, transparent, black 3.5em);
			mask-size: 100% 100%


		}

		.card-image {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}

		.card-btn {
			position: absolute;
			bottom: -50px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
			opacity: 0;
			transition: all .3s ease;
			padding: .5em 1.2em;
			font-size: 1rem;

			&::before {
				height: 100%;
				top: 0;
			}
		}
	}

	&:hover, &:active, &:focus {

		.card-image-wrap {
			box-shadow: inset 0 0 0 2000px rgba(0,0,0, .6);
		}

		.info-wrap {
			opacity: 1;
			visibility: visible;
		}

		.card-btn {
			bottom: 0;
			opacity: 1;
		}
	}

	.project-card-title {
		margin-top: .5rem;
	}
}