.hero-image-transition-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	overflow: hidden;
	background-color: $black;

	.image-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
	}

	// non animated state
	[data-hs-item]:not([data-hs-is-active="true"]) {
		opacity: 0;
		transform: scale(1);
		transition: all 4.8s ease-in-out;
	}
//cubic-bezier(0.33, 1, 0.68, 1)

	[data-hs-is-active="true"] {
		opacity: 1;
		transform: scale(1.2);
		transition: all 5s ease-in-out;
	}
}