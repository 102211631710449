.page-header {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-content: center;
	justify-content: center;
	padding-top: 6rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;

	@include breakpoint(large) {
		padding-top: 1rem
	}

	background-position: center center;
	background-size: cover;


	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: rgba(0, 0, 0, .5);
	}

	h1 {
		text-align: center;
		color: white;
		z-index: 1;
	}
}