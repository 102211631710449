#oral-history-hero {
	.hero-col-grid {
		display: flex;
		flex-flow: column-reverse nowrap;

		@include breakpoint(xlarge) {
			flex-flow: row nowrap;
		}

		> div {
			width: 100%;

			@include breakpoint(xlarge) {
				width: 50%;
			}
		}
	}

	.hero-content-wrap {
		position: relative;
		overflow: visible;
		padding-top: 8rem;
		padding-left: 0;
		padding-right: 2rem;
		padding-bottom: 8rem;
		align-self: flex-start;
		max-width: 30rem;

		display: flex;
		flex-flow: column nowrap;
		justify-content: center;

		@include breakpoint(xlarge) {
			padding-top: 8rem;
			width: 60%;
		}

		&::after {
			content: "";
			background-color: $quaternary-color;
			position: absolute;

			height: 100%;
			width: 110%;
			left: -14%;
			bottom: 0;
			z-index: -1;

			transform: skew(-5deg);

			// Huh this actually worked really well. Way better then the nonsense going on the front page
			@include breakpoint(medium) {
				width: calc(110% + #{$global-gutter-medium * 2});
				left: -$global-gutter-medium * 2;
			}
		}

		a {
			align-self: flex-start;
			margin-top: 2rem;
		}

		.hero-desc {
			padding-top: 1.5rem;
		}
	}

	.hero-image-container {
		align-self: center;
		margin-right: auto;
		position: relative;
		//width: auto !important;
		margin-bottom: -3rem;
		z-index: 3;

		.hero-image {
			width: 400px;
			max-width: 100%;
			margin-right: auto;
			position: relative;

			@include breakpoint(xlarge) {
				width: max-content;
			}
		}

		.embed-container {
			position: relative;
			padding-bottom: 56.25%;
			width: 100%;
			overflow: hidden;
			box-shadow: 2px 2px 8px 0 rgba(0,0,0, .25);

			@include breakpoint(medium) {
				padding-bottom: 75%;
			}
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

#recent-videos {
	.recent-videos-title {
		position: relative;
		padding-top: 1rem;
		padding-bottom: 4rem;
		padding-left: 1rem;
		padding-right: 2rem;
		align-self: start;
		color: white;

		@include breakpoint(medium) {
			padding-right: 4rem;
		}

		&::after {
			content: "";
			background-color: $tertiary-color;
			position: absolute;

			height: 100%;
			width: 110%;
			left: -14%;
			bottom: 0;
			z-index: -1;

			transform: skew(-10deg);

			// Huh this actually worked really well. Way better then the nonsense going on the front page
			@include breakpoint(medium) {
				width: calc(110% + #{$global-gutter-medium * 2});
				left: -$global-gutter-medium * 2;
			}
		}
	}

	.videos-repeater {
		margin-top: -2rem;
		width: 100%;
		display: grid;
		align-items: flex-start;
		justify-content: center;
		grid-template-columns: 1fr;
		grid-gap: 15px;

		@include breakpoint(medium) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include breakpoint(xlarge) {
			grid-template-columns: repeat(3, 1fr);
		}

		.video-single {
			color: $black;

			&:hover {
				.video-title {
					color: $secondary-color;
				}
			}

			.video-author-name {
				margin-top: 0.5rem;
				font-style: italic;
			}

			.video-title {
				transition: all 0.3s ease;
			}

			.video-image {
				width: 100%;
				height: 175px;
				object-fit: cover;

				@include breakpoint(medium) {
					height: 225px;
				}
			}
		}
	}
}

#tell-us-your-story {
	form {
		label {
			color: white;
		}

		.ginput_container_fileupload {
			input {
				fill: white;
			}
		}
	}


	h3 {
		color: white;
	}

	.fancy-grid-wrap {
		position: relative;
		max-width: 40rem;
		padding-top: 3rem;
		padding-bottom: 3rem;

		@include breakpoint(medium) {
			padding-right: 3rem;
		}

		&::after {
			content: "";
			background-color: $tertiary-color;
			position: absolute;

			height: 100%;
			width: 160%;
			left: -40%;
			bottom: 0;
			z-index: -1;

			transform: skew(-5deg);

			// Huh this actually worked really well. Way better then the nonsense going on the front page
			@include breakpoint(medium) {
				width: calc(110% + #{$global-gutter-medium * 2});
				left: -$global-gutter-medium * 2.5;
			}
		}
	}
}
