#section-404 {
	color: white;
	min-height: 100vh;
	position: relative;
	background-size: cover;
	background-position: center center;
	z-index: 0;

	.background-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: rgba(0, 0, 0, 0.35);
		z-index: -1;
	}

	.super-inner-wrap {
		position: relative;
		z-index: 1;
	}

	.constrain-wrap {
		max-width: 500px;
		width: 100%;
		margin-top: auto;
		margin-bottom: auto;

		.desc {
			margin-top: 20px;
			font-style: normal;
			font-weight: normal;
		}

		.fancy-btn {
			margin-top: 20px;
			display: inline-block;
		}
	}
}
